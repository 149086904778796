import axios from "axios";
import { Base64 } from "js-base64";
import { decrypt, encrypt } from "../AES_GCM";
import { handleAPIError } from "../../views/Logout/HandleError";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ENABLE_AES_ENCRYPTION = JSON.parse(
  process.env.REACT_APP_ENABLE_AES_ENCRYPTION
);
const getHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "instance-token": cookies.get("user-token"),
});

export const handleErrorData = async (error) => {
  if (error.response && error.response.data) {
    const decodedData = ENABLE_AES_ENCRYPTION
      ? await decrypt(error.response.data)
      : Base64.decode(error.response.data);
    error.response.data = JSON.parse(decodedData);
  }
  return error;
};

const handleResponse = async (response) => {
  console.log(
    `[API RESPONSE] ${
      response.config.url
    } - Received at: ${new Date().toLocaleTimeString("en-US")}`
  );

  if (response.data) {
    const decodedData = ENABLE_AES_ENCRYPTION
      ? await decrypt(response.data)
      : Base64.decode(response.data);
    response.data = JSON.parse(decodedData);
  }

  console.log(`RESPONSE ${response.config.url} -`, response.data);

  return response;
};

const logRequest = (method, url, params, data = null) => {
  console.log(
    `[API REQUEST] ${method.toUpperCase()} ${url} - Sent at  ${new Date().toLocaleTimeString(
      "en-US"
    )}`
  );
  if (params) console.log(`[PARAMS]`, params);
  if (data) console.log(`[DATA]`, data);
};

export const getAPICall = (url, params) => {
  logRequest("get", url, params);
  return axios
    .get(url, { headers: getHeaders(), params })
    .then(handleResponse)
    .catch((error) => handleAPIError(error));
};

export const postAPICall = async (url, data, params) => {
  logRequest("post", url, params, data);
  const encodedData = ENABLE_AES_ENCRYPTION
    ? await encrypt(JSON.stringify(data))
    : Base64.encode(JSON.stringify(data));
  try {
    const response = await axios.post(url, encodedData, {
      headers: getHeaders(),
      params,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};

export const putAPICall = async (url, data, params) => {
  logRequest("put", url, params, data);
  const encodedData = ENABLE_AES_ENCRYPTION
    ? await encrypt(JSON.stringify(data))
    : Base64.encode(JSON.stringify(data));
  try {
    const response = await axios.put(url, encodedData, {
      headers: getHeaders(),
      params,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};

export const deleteAPICall = async (url, data, params) => {
  logRequest("delete", url, params, data);
  const encodedData = data
    ? ENABLE_AES_ENCRYPTION
      ? await encrypt(JSON.stringify(data))
      : Base64.encode(JSON.stringify(data))
    : null;
  try {
    const response = await axios.delete(url, {
      headers: getHeaders(),
      params: params,
      data: encodedData,
    });
    return handleResponse(response);
  } catch (error) {
    return await handleAPIError(error);
  }
};
