import { getAPICall, postAPICall, putAPICall } from "../axiosMethodCall";
import { toast } from "react-toastify";
import {
  getEmployeeDetailsUrl,
  createEmployeeUrl,
  getParticularEmployeeUrl,
  employeePasswordUrl,
  getParticularEmployeeAccessLocationUrl,
  stateLocation,
  areaLocation,
  clusterLocation,
  branchLocation,
  employeeForgotPasswordUrl,
  getEmployeeLogoutUrl,
} from "../../urlConfiguration";

export const createEmployee = async (payload) => {
  try {
    const response = await postAPICall(createEmployeeUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeDetails = async () => {
  try {
    const response = await getAPICall(getEmployeeDetailsUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const getParticularEmployeeDetails = async (id) => {
  try {
    const url = getParticularEmployeeUrl;
    const response = await getAPICall(url, id);
    return response;
  } catch (error) {
    if (error?.response?.status) {
      toast.error(error?.response?.data?.message);
      return error?.response;
    }
    return error;
  }
};

export const updateParticularEmployeeDetails = async (payload, id) => {
  try {
    const url = getParticularEmployeeUrl;
    const response = await putAPICall(url, payload, id);
    return response;
  } catch (error) {
    return error;
  }
};

export const employeeChangePassword = async (payload) => {
  const url = employeePasswordUrl;
  try {
    const response = await putAPICall(url, payload);
    return response;
  } catch (error) {
    return error;
  }
};

// Perticular Employee AccessLocation URL's

export const getPerticularEmployeeAccessLocation = async (id) => {
  try {
    const url = getParticularEmployeeAccessLocationUrl(id);
    const response = await getAPICall(url);
    return response;
  } catch (error) {
    if (error?.response?.status) {
      toast.error(error?.response?.data?.message);
      return error?.response;
    }
    return error;
  }
};

//Update location and branch data in update employee
//get state location
export const getUpdateEmpStatelocation = async (payload) => {
  const { zone_code, employee_id, hierarchy_id } = payload;
  try {
    const params = new URLSearchParams();
    zone_code.forEach((zoneCode) => {
      params.append("zone_code", zoneCode);
    });

    const url = `${stateLocation}?${params.toString()}&employee_id=${employee_id}&hierarchy_id=${hierarchy_id}`;
    const response = await getAPICall(url);
    return response;
  } catch (error) {
    if (error?.response && error?.response?.status === 422)
      return toast.error(error?.response?.data.errors.code[0]);
  }
};
//get cluster location
export const getUpdateEmpClusterlocation = async (payload) => {
  const { state_code, employee_id, hierarchy_id } = payload;
  try {
    const params = new URLSearchParams();
    state_code.forEach((statecode) => {
      params.append("state_code", statecode);
    });
    const url = `${clusterLocation}?${params.toString()}&employee_id=${employee_id}&hierarchy_id=${hierarchy_id}`;
    const response = await getAPICall(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  } catch (error) {
    if (error?.response && error?.response?.status === 422)
      return toast.error(error?.response?.data.errors.code[0]);
  }
};
//get area location
export const getUpdateEmpArealocation = async (payload) => {
  const { cluster_code, employee_id, hierarchy_id } = payload;
  try {
    const params = new URLSearchParams();
    cluster_code.forEach((clustercode) => {
      params.append("cluster_code", clustercode);
    });
    const url = `${areaLocation}?${params.toString()}&employee_id=${employee_id}&hierarchy_id=${hierarchy_id}`;
    const response = await getAPICall(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  } catch (error) {
    if (error?.response && error?.response?.status === 422)
      return toast.error(error?.response?.data.errors.code[0]);
  }
};

//get branch location
export const getUpdateEmpBranchlocation = async (payload) => {
  const { area_code, bc_code, employee_id, hierarchy_id } = payload;
  try {
    const params1 = new URLSearchParams();
    area_code.forEach((areacode) => {
      params1.append("area_code", areacode);
    });
    const params2 = new URLSearchParams();
    bc_code.forEach((areacode) => {
      params2.append("bc_code", areacode);
    });
    const url = `${branchLocation}?${params1.toString()}&${params2.toString()}&employee_id=${employee_id}&hierarchy_id=${hierarchy_id}`;
    //this is how params must go in get call
    //  const queryParams = new URLSearchParams({
    //   area_code: payload.area_code.join(","), // Convert array to comma-separated string
    //   bc_code: payload.bc_code.join(","),
    // });
    const response = await getAPICall(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  } catch (error) {
    if (error?.response && error?.response?.status === 422)
      return toast.error(error?.response?.data.errors.code[0]);
  }
};

export const employeeForgotPassword = async (payload) => {
  try {
    const response = await putAPICall(employeeForgotPasswordUrl, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getLogoutAPIUrl = async (payload) => {
  try {
    const response = await postAPICall(getEmployeeLogoutUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
};
