import { encrypt } from "../../../components/AES_GCM";
import { encryptData } from "../../../components/crypto";
import { getAPICall } from "../../../components/dataLayer/axiosMethodCall";
import { getFeaturesDetailsURL } from "../../../components/urlConfiguration";

export const FETCH_USER_FEATURES_REQUEST = "FETCH_USER_FEATURES_REQUEST";
export const FETCH_USER_FEATURES_SUCCESS = "FETCH_USER_FEATURES_SUCCESS";
export const FETCH_USER_FEATURES_FAILURE = "FETCH_USER_FEATURES_FAILURE";

export const fetchUserFeatures = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_FEATURES_REQUEST });

    getAPICall(getFeaturesDetailsURL)
      .then((response) => {
        dispatch({
          type: FETCH_USER_FEATURES_SUCCESS,
          payload: encryptData(response?.data?.features),
          userData: encryptData(response?.data?.data),
          is_cbs_holiday: encryptData(response?.data?.is_cbs_holiday),
          holiday_reason: encryptData(response?.data?.holiday_reason),
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_USER_FEATURES_FAILURE, payload: error.message });
      });
  };
};
