export const logoutUser = (history) => {
  // Clear the session timeout
  clearSessionTimeout();

  // Remove the user token from sessionStorage
  sessionStorage.removeItem("user-token");

  // Redirect to the home page or login page
  history.push("/login");
};

// Function to clear session timeout
export const clearSessionTimeout = () => {
  const timeoutId = sessionStorage.getItem("timeout-id");
  if (timeoutId) {
    clearTimeout(timeoutId); // Clear the timeout to prevent it from firing
    sessionStorage.removeItem("timeout-id"); // Remove the timeout ID from sessionStorage
  }
};
