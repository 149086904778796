import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { decryptData } from "../../../components/crypto";
import NotificationDropdown from "./NotificationDropdown";
import OnlyFeaturesPermission from "../../../OnlyFeaturesPermission";
import S3ImageWithFallback from "../../../components/CommonComponents/S3ImageWithFallback";
import { logoutUserAPI } from "../../../views/Logout/Logout";

const UserDropdown = () => {
  const history = useHistory();
  const goToProfilePage = () => {
    history.push("/profile");
  };
  const goTActivityPage = () => {
    history.push("/activity");
  };

  return (
    <DropdownMenu right className="w-100">
      <DropdownItem
        className="w-100"
        onClick={(e) => {
          goToProfilePage();
        }}
      >
        <div className="">
          <Icon.User
            size={14}
            // className="me-75"
            style={{ marginRight: "10px" }}
          />
          <span className="align-middle">User Profile</span>
        </div>
      </DropdownItem>
      <DropdownItem
        className="w-100"
        onClick={(e) => {
          goTActivityPage();
        }}
      >
        <div className="">
          <Icon.Clock size={14} style={{ marginRight: "10px" }} />
          <span className="align-middle">Activity</span>
        </div>
      </DropdownItem>
      <DropdownItem onClick={() => logoutUserAPI(history)} className="w-100">
        <div className="">
          <Icon.Power
            size={14}
            // className="me-75"
            style={{ marginRight: "10px" }}
          />
          <span className="align-middle">Logout</span>
        </div>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = (props) => {
  const decryptDataSet = useSelector((state) => state.userFeatures.userData);
  const userData = decryptData(decryptDataSet);
  const formattedDate = (userDataset) => {
    if (userDataset !== null && userDataset.last_login !== undefined) {
      const originalDate = moment.utc(userDataset.last_login);
      return moment(originalDate).format("DD-MM-YYYY, HH:mm:ss");
    } else return "NA";
  };
  const formattedFailedDate = (userDataset) => {
    if (
      userDataset !== null &&
      userDataset.failed_login_datetime !== undefined
    ) {
      const originalDate = moment.utc(userDataset.failed_login_datetime);
      return moment(originalDate).format("DD-MM-YYYY, HH:mm:ss");
    } else return "NA";
  };
  const firstName = userData ? userData.first_name : "NA";
  const lastName = userData ? userData.last_name : "";
  const role = userData && userData.role ? userData.role : "NA";
  const avatar =
    userData && userData.profile_image ? userData.profile_image : props.userImg;
  const last_login = formattedDate(userData);
  const failed_login = formattedFailedDate(userData);
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <OnlyFeaturesPermission
        requiredFeature={"feature_for_update_notification"}
      >
        <NotificationDropdown avatar={avatar} />
      </OnlyFeaturesPermission>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-flex d-sm-flex">
            <span className="user-name fw-bold">
              {firstName} {lastName}
            </span>
            <span className="user-role">&#40;{role}&#41;</span>
            {last_login !== "Invalid date" ? (
              <span className="user-status">
                <span style={{ fontWeight: 800 }}>Last Login: </span>
                <>{last_login}</>
              </span>
            ) : (
              <></>
            )}
            {failed_login !== "Invalid date" ? (
              <span className="user-status " style={{ marginTop: "8px" }}>
                <span style={{ fontWeight: 800 }}>Last Failed Login: </span>
                <>{failed_login}</>
              </span>
            ) : (
              <></>
            )}
          </div>
          <span data-tour="user">
            <S3ImageWithFallback
              src={avatar}
              className="round"
              height="40"
              width="40"
              alt="avatar"
              style={{ objectFit: "cover" }}
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  );
};

export default NavbarUser;
