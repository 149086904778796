import React from "react";
import { toast } from "react-toastify";
import { decrypt } from "../../components/AES_GCM";
import { Base64 } from "js-base64";
import { handleErrorData } from "../../components/dataLayer/axiosMethodCall";
import { getLogoutAPIUrl } from "../../components/dataLayer/EmployeeLayer";
import { logoutUser } from "../../layouts/components/navbar/LogoutUser";
import { getNavigate } from "./navigationService";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ENABLE_AES_ENCRYPTION = JSON.parse(
  process.env.REACT_APP_ENABLE_AES_ENCRYPTION
);

let isToastVisible = false;

const logoutUserAPI = async () => {
  try {
    getNavigate().push("/logout");
    const payload = { timeout: true };
    const response = await getLogoutAPIUrl(payload);
    if (response?.status === 200) {
      cookies.remove("user-token", { path: "/" });
      cookies.remove("user", { path: "/" });
      getNavigate().push("/login");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
// ✅ handleAPIError now takes logoutUserAPI as a parameter
export async function handleAPIError(error) {
  if (error.response && [403, 401, 440].includes(error.response.status)) {
    if (!isToastVisible) {
      isToastVisible = true;
      const binaryMessage = error?.response?.data;
      const decodedMessage = ENABLE_AES_ENCRYPTION
        ? await decrypt(binaryMessage)
        : JSON.parse(Base64.decode(binaryMessage));
      const decode_message = JSON.parse(decodedMessage);

      toast.error(decode_message?.message, {
        onClose: async () => {
          logoutUserAPI();
          isToastVisible = false;
        },
      });
    }
  } else if (error.response?.status === 500) {
    toast.error(error.response.statusText);
  } else if ([422, 400, 404, 409].includes(error.response?.status)) {
    const binaryMessage = error?.response?.data;
    const decodedMessage = ENABLE_AES_ENCRYPTION
      ? await decrypt(binaryMessage)
      : JSON.parse(Base64.decode(binaryMessage));
    const decode_message = JSON.parse(decodedMessage);
    toast.error(decode_message?.errors);
  }

  return Promise.reject(await handleErrorData(error));
}

const HandleError = () => {
  return <div></div>;
};

export default HandleError;
